import React from 'react';

export const IconAboutUsBanner = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="78.273"
      height="62.649"
      viewBox="0 0 78.273 62.649"
    >
      <g id="Group_493" data-name="Group 493" transform="translate(-6034.629 -366.834)">
        <g id="Group_492" data-name="Group 492">
          <path
            id="Path_1031"
            data-name="Path 1031"
            d="M6084.811,406.527c2.652-3.178,3.034-8.33,3.011-8.315a15.8,15.8,0,0,0-1.892-6.759l-.11-.194a16.309,16.309,0,0,0-4.335-4.344,1.135,1.135,0,0,0-1.26,0c-1.106.726-1.439,1.009-2.657,1.861-.432.3-.3.87.146,1.154a13.959,13.959,0,0,1,3.737,3.627c.053.072.094.13.123.172a9.925,9.925,0,0,1,1.21,6.223,3.331,3.331,0,0,1-.169.6c-.228.693.365,3.462-14.475,12.231-6.135,4.024-12.963,8.237-15.048,9.4-3.117,1.744-5.494.916-8.386-.805a7.628,7.628,0,0,1-3.81-5.411c-.24-1.53-.543-4.2,1.139-6.107,1.9-2.162,6.741-5.762,13.6-10.524a1.949,1.949,0,0,0,.759-1.55,37.935,37.935,0,0,0,.049-3.821c-.009-.513-.434-.884-.858-.594-9.185,6.288-15.427,10.826-18,13.746a12.1,12.1,0,0,0-2.791,10.068,15.358,15.358,0,0,0,2.8,6.659,12.458,12.458,0,0,0,3.574,3.314c4.534,2.7,8.868,2.711,13.64.041,3.5-1.955,19.907-12.507,25.088-16.31a26.364,26.364,0,0,0,4.856-4.334Z"
            fill="#fff"
          />
          <path
            id="Path_1032"
            data-name="Path 1032"
            d="M6110.874,373.857l-.109-.2a15.728,15.728,0,0,0-9.321-6.529c-4.109-.878-8.415.178-12.8,3.14-10.766,7.274-18.744,12.737-23.246,16.545-.069.039-.139.089-.208.129-1.772,1.63-5.413,5.538-5.616,10.245l.016-.011a14.174,14.174,0,0,0,.1,1.925c.028.169.056.337.094.507l-.037.008a15.352,15.352,0,0,0,2.8,6.634,12.805,12.805,0,0,0,2.96,2.914,1.512,1.512,0,0,0,1.331.153c1.5-.88,2.575-1.841,3.734-2.616a.563.563,0,0,0-.1-.993,10.35,10.35,0,0,1-1.589-.783,9.177,9.177,0,0,1-3.8-6.17,6.761,6.761,0,0,1,1.508-5.661c.251-.284.563-.6.919-.935,1.948-2.408,8.584-6.848,11.17-8.532,3.713-2.6,8.324-6.323,13.225-9.634,3.1-2.094,5.992-2.883,8.6-2.344a10.172,10.172,0,0,1,5.746,3.873c.053.072,1.037,1.535,1.066,1.576,2.853,5.685.754,9.046-4.453,12.868-1.982,1.455-6.362,4.385-10.288,6.96a1.929,1.929,0,0,0-.825,1.38,4.179,4.179,0,0,0,.19,2.206c.167.762.886,1.774,1.54,1.349,4.865-3.16,9.741-6.413,12.163-8.191C6109.108,391.124,6116.459,384.773,6110.874,373.857Z"
            fill="#fff"
          />
        </g>
        <path
          id="Path_1033"
          data-name="Path 1033"
          d="M6087.887,415.458c-.683-.924-1.2-1.086-.485-2.063a2.449,2.449,0,0,1,3.52-.766c2.5,1.827,5.908,4.038,6.837,4.661,1.835,1.231,2.574.992,2.069,2.395s-1.273,1.468-2.675.963a61.234,61.234,0,0,1-8.687-4.617A2.715,2.715,0,0,1,6087.887,415.458Z"
          fill="#fff"
        />
        <path
          id="Path_1034"
          data-name="Path 1034"
          d="M6083.734,428.74a2.8,2.8,0,0,1-.172-.263c-1.229-2.137-2.243-8.409-2.525-9.58-.35-1.449.142-2.122,1.59-2.471s2.85.077,3.2,1.529a49.4,49.4,0,0,0,2.414,7.83c.744,1.292.191,2.363-1.1,3.106C6085.939,429.582,6084.538,429.826,6083.734,428.74Z"
          fill="#fff"
        />
        <path
          id="Path_1035"
          data-name="Path 1035"
          d="M6046.994,379.921c-.453-.612-.1-1.36.083-2.161.333-1.453.91-1.928,2.363-1.6,2.4.551,7.765,3.515,8.816,4.1,1.3.727,1.8,1.413,1.072,2.713s-1.282,1.546-2.66.98c-2.8-1.152-7.018-2.766-8.109-3.017A2.684,2.684,0,0,1,6046.994,379.921Z"
          fill="#fff"
        />
        <path
          id="Path_1036"
          data-name="Path 1036"
          d="M6062.727,379.271a8.891,8.891,0,0,1-.583-1.839,27.419,27.419,0,0,0-2.679-6.666c-.743-1.292-.779-2.181.513-2.924s2.638-.671,3.381.621c1.23,2.137,2.7,8.085,2.983,9.256.35,1.45-.461,2.259-1.91,2.609A1.416,1.416,0,0,1,6062.727,379.271Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
