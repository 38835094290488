import React from 'react';
import { IconSvg } from '../icon-props';

export const IconCareersMessage = (props: React.CSSProperties) => {
  return (
    <IconSvg
      width={props.width || '227.915'}
      height={props.height || '180.571'}
      viewBox="0 0 227.915 180.571"
      fill={props.fill}
      style={{ ...props }}
    >
      <g id="Group_737" data-name="Group 737" transform="translate(-1145.678 -884.613)">
        <path
          id="Path_53"
          data-name="Path 53"
          d="M5120.934,8916.942a.762.762,0,0,1,.224-.483c1.765-1.266,4.7-9.1,6.268-12.861a1.347,1.347,0,0,1,1.5-.95,1.251,1.251,0,0,1,.951,1.5c-1.546,6.942-6.5,13.552-6.716,13.829a1.254,1.254,0,0,1-2.223-1.029Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#00a8e9"
        />
        <path
          id="Path_54"
          data-name="Path 54"
          d="M5076.857,8898.333a1.252,1.252,0,0,1,1.013-.963,1.375,1.375,0,0,1,1.446,1.024c1.434,3.5,1.542,3.017,4.179,10.222.238.649,1.561,3.341.966,3.693a1.253,1.253,0,0,1-1.718-.437c-.21-.354-5.157-8.718-5.9-13.055A1.24,1.24,0,0,1,5076.857,8898.333Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#00a8e9"
        />
        <g id="Group_736" data-name="Group 736">
          <path
            id="Path_49"
            data-name="Path 49"
            d="M5057.309,8930.1s-37.011,0-45.2,1.413-16.669,7.91-16.1,27.122,1.13,42.944,3.673,49.725,16.386,10.171,42.661,10.454,31.925-.849,34.185.847,14.409,13.562,18.929,15.257-2.825-8.194-2.26-15.539c0,0,14.974,1.412,20.624-.283s6.5-9.888,7.064-18.363-.565-51.419-.848-58.482-4.238-12.431-20.624-13S5057.309,8930.1,5057.309,8930.1Z"
            transform="translate(-3784.472 -8012.74)"
            fill="#d4daea"
          />
          <path
            id="Path_52"
            data-name="Path 52"
            d="M5143.574,8949.4c.094-.423,4.382-2.434,4.824-2.573.073-.023,3.115-1.795,7.449-4.694a1.354,1.354,0,0,1,1.773.02c.484.5.959,1.626-.8,2.931a43.411,43.411,0,0,1-11.642,5.784,1.254,1.254,0,0,1-1.6-1.467Z"
            transform="translate(-3784.472 -8012.74)"
            fill="#00a8e9"
          />
          <path
            id="Path_130"
            data-name="Path 130"
            d="M4965.987,8974.593s-24.014-1.694-30.23,5.085-3.673,19.777-3.39,33.9.565,42.1,5.933,43.226,12.713,0,14.974,1.979-.565,11.583-1.7,13.844-1.7,4.8.848,3.39,15.539-13,18.929-16.1,46.052-2.26,53.68-3.108,23.449,0,22.884-17.8,0-53.679-2.543-59.047-7.345-9.324-25.709-8.476S4965.987,8974.593,4965.987,8974.593Z"
            transform="translate(-3784.472 -8012.74)"
            fill="none"
            stroke="#151f35"
            stroke-miterlimit="10"
            stroke-width="3"
          />
          <path
            id="Path_131"
            data-name="Path 131"
            d="M4970.071,9023.844s4.521,1.131,4.8-5.367-6.78-4.8-8.475-2.543S4965.551,9023.844,4970.071,9023.844Z"
            transform="translate(-3784.472 -8012.74)"
            fill="none"
            stroke="#151f35"
            stroke-miterlimit="10"
            stroke-width="3"
          />
          <path
            id="Path_132"
            data-name="Path 132"
            d="M4993.715,9022.847s4.222-1.97.348-7.194-8.292.542-8.184,3.365S4990.2,9025.7,4993.715,9022.847Z"
            transform="translate(-3784.472 -8012.74)"
            fill="none"
            stroke="#151f35"
            stroke-miterlimit="10"
            stroke-width="3"
          />
          <path
            id="Path_133"
            data-name="Path 133"
            d="M5013.029,9021.658s4.222-1.97.348-7.194-8.292.542-8.185,3.365S5009.518,9024.506,5013.029,9021.658Z"
            transform="translate(-3784.472 -8012.74)"
            fill="none"
            stroke="#151f35"
            stroke-miterlimit="10"
            stroke-width="3"
          />
        </g>
      </g>
    </IconSvg>
  );
};
