import { Theme, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { FlexBox } from '../primitives/flex';

export interface CareerInfoCardProps {
  theme: Theme;
  heading: string;
  details: string;
  Icon: JSX.Element;
}

export default function CareerInfoCard(props: CareerInfoCardProps) {
  const { theme, heading, details, Icon } = props;
  return (
    <Box
      sx={{
        maxWidth: '325px',
        marginX: 'auto',
        marginY: '20px',
        [theme.breakpoints.down('md')]: { maxWidth: '100%' }
      }}
    >
      <FlexBox width="100%" height="250px" alignItems="center" justifyContent="center">
        {Icon}
      </FlexBox>
      <Typography variant="h6" textAlign="center" py="10px">
        {heading}
      </Typography>
      <Typography textAlign="center" py="10px">
        {details}
      </Typography>
    </Box>
  );
}
