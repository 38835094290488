import { styled } from '@mui/material';
import { Box, Theme } from '@mui/system';

export const HeroContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  position: 'static',
  // width: '100%',
  margin: 'auto',
  [theme.breakpoints.up('md')]: {
    // width: '85%',
    position: 'absolute',
    bottom: '-80px'
    // left: '10%'
  }
}));
