import React from 'react';
import { Box, useTheme } from '@mui/system';
import { Typography } from '@mui/material';
import { HeroContainer } from './primitives/hero-container';
import { HeroBox } from './primitives/hero-box';
import { Width80Box } from './primitives/Width80Box';
import { IconAboutUsBanner } from '../assets/icons/about-us/about-us-banner';

export interface HeroSectionProps {
  icon: any;
  title: string | JSX.Element;
  bgImage: string;
  bgImageStyle?: React.CSSProperties;
}

export const HeroSection = (props: HeroSectionProps) => {
  const { icon, title, bgImage, bgImageStyle } = props;
  console.log(icon, 'icon')
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          position: 'relative',
          [theme.breakpoints.down('md')]: {
            paddingTop: '60px'
          }
        }}
      >
        <Width80Box margin="auto">
          <HeroContainer>
            <HeroBox padding="28px">
              <Box display="flex" flexDirection="column" alignItems="center" my={'35px'}>
                <Box>{icon ? (
              <img
                src={icon}
                style={{ objectFit: 'cover', objectPosition: 'center', width:"78.273px", height:"62.649px"}}
              />
            ) : (
              <IconAboutUsBanner />
            )}</Box>
                <Typography
                  marginY="16px"
                  textAlign="center"
                  fontWeight={500}
                  variant="h4"
                  sx={{
                    maxWidth: '80%',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '24px',
                      maxWidth: '100%'
                    }
                  }}
                >
                  {title}
                </Typography>
              </Box>
            </HeroBox>
          </HeroContainer>
        </Width80Box>
        <Box
          sx={{
            background: `url(${bgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            height: '600px',
            [theme.breakpoints.down('md')]: {
              height: '300px'
            },
            ...bgImageStyle
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          height: '80px',
          [theme.breakpoints.down('md')]: {
            height: '0px'
          }
        }}
      ></Box>
    </>
  );
};
