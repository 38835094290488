import React from 'react';
import { IconSvg } from '../icon-props';

export const IconCareersPlane = (props: React.CSSProperties) => {
  return (
    <IconSvg
      width={props.width || '241.275'}
      height={props.height || '133.856'}
      viewBox="0 0 241.275 133.856"
      fill={props.fill}
      style={{ ...props }}
    >
      <g id="Group_734" data-name="Group 734" transform="translate(-253.302 -920.318)">
        <g id="Group_22" data-name="Group 22" transform="translate(-3784.472 -8012.74)">
          <path
            id="Path_117"
            data-name="Path 117"
            d="M4132.816,9024.055a1.513,1.513,0,0,1-1.54-.677l-16.321-25.559a1.516,1.516,0,0,1,.052-1.708c.194-.268,19.774-26.834,56.924-50.107,31.549-19.768,70.4-11.042,70.786-10.951a1.517,1.517,0,0,1,1.125,1.854c-.414,1.613-10.471,39.744-40.495,58.914-29.745,18.994-70.06,28.131-70.463,28.221Zm-14.736-26.977,15.173,23.763c6.451-1.551,40.512-11.156,67.163-28.173,25.665-16.388,36.993-47.2,39.217-54.562-7.452-1.361-36.273-6.217-63.2,10.649C4143.455,8969.417,4121.654,8992.469,4118.08,8997.078Z"
            fill="#151f35"
          />
          <path
            id="Path_118"
            data-name="Path 118"
            d="M4116.5,8998.5a1.54,1.54,0,0,1-.4.017l-22.9-2.046a1.516,1.516,0,0,1-.838-2.673c.154-.128,16.282-14.06,30.17-25.176,5.693-4.559,10.975-6.579,15.7-6.011a8.051,8.051,0,0,1,6.327,4.783,1.516,1.516,0,0,1-2.4,1.858,7.937,7.937,0,0,0-5.178-2.524c-3.814-.427-8.437,1.434-13.364,5.377-10.315,8.257-21.426,17.453-26.5,21.672l19.241,1.72a1.516,1.516,0,0,1,.128,3Z"
            fill="#151f35"
          />
          <path
            id="Path_119"
            data-name="Path 119"
            d="M4126.606,9045.174a1.516,1.516,0,0,1-1.688-2.006l7.776-21.631a1.516,1.516,0,1,1,2.853,1.026l-6.535,18.179c5.962-2.829,18.98-9.041,30.81-14.924,5.692-2.831,9.332-6.27,10.526-9.94a8.01,8.01,0,0,0-.133-5.672,1.516,1.516,0,0,1,2.69-1.4c.073.142,3.43,3.738,1.968,8.232-1.472,4.526-5.529,8.469-12.059,11.716-15.928,7.922-35.644,16.214-35.824,16.3A1.489,1.489,0,0,1,4126.606,9045.174Z"
            fill="#151f35"
          />
          <path
            id="Path_120"
            data-name="Path 120"
            d="M4233.341,8960.243a1.5,1.5,0,0,1-.685-.038,22.646,22.646,0,0,1-11.664-9.265c-4.486-7.025-4.453-15.945-4.449-16.321a1.516,1.516,0,0,1,3.032.027c0,.084-.02,8.411,3.972,14.661a19.747,19.747,0,0,0,9.957,7.987,1.516,1.516,0,0,1-.163,2.948Z"
            fill="#151f35"
          />
          <path
            id="Path_121"
            data-name="Path 121"
            d="M4196.3,8983.871a12.663,12.663,0,0,1-9.8-2.316c-4.662-3.5-7.466-8.163-7.693-12.8-.215-4.395,1.771-8.542,5.9-12.326,10.386-9.512,21.264-1.194,21.373-1.108a1.5,1.5,0,0,1,.2.188c8.873,10.018,3.531,18.56-.442,22.761A17.389,17.389,0,0,1,4196.3,8983.871Zm-2.6-28.806a13.716,13.716,0,0,0-6.94,3.6c-3.435,3.147-5.092,6.492-4.923,9.943.183,3.729,1.421,6.255,5.359,9.21,7.128,5.349,15.5-2.681,16.323-3.546,3.965-4.189,7.371-8.891.595-16.641A14.418,14.418,0,0,0,4193.692,8955.065Z"
            fill="#151f35"
          />
          <path
            id="Path_122"
            data-name="Path 122"
            d="M4104.97,9034.452c-8.377,1.471-17.473-1.105-17.956-1.2a1.516,1.516,0,0,1-1.173-1.9c.089-.314,2.286-7.837,11.1-18.073,8.989-10.437,22.6-11.976,23.179-12.035.831-.088-.088.405,0,1.237s1.145,1.709.318,1.776c-.129.019-13.013,1.5-21.2,11a58.332,58.332,0,0,0-9.88,15.287c4.324.557,15.55,1.43,22.774-3.182a49.872,49.872,0,0,0,14.243-13.843c.442-.71.01-.055.722.388s3.094,1.533,2.654,2.245a52.026,52.026,0,0,1-15.191,14.8A26.277,26.277,0,0,1,4104.97,9034.452Z"
            fill="#151f35"
          />
          <path
            id="Path_123"
            data-name="Path 123"
            d="M4109.333,9021.224a22.78,22.78,0,0,1-6.226.318,1.517,1.517,0,0,1-1.167-2.19c3.88-7.659,18.7-14.557,19.328-14.847a1.516,1.516,0,0,1,1.269,2.753c-.129.061-11.568,5.384-16.446,11.306a25.971,25.971,0,0,0,17.781-8.857,1.516,1.516,0,0,1,2.246,2.037A29.318,29.318,0,0,1,4109.333,9021.224Z"
            fill="#151f35"
          />
          <path
            id="Path_124"
            data-name="Path 124"
            d="M4059.8,9066.891a1.515,1.515,0,0,1-1.3-2.6,120.107,120.107,0,0,1,16.465-12.759,168.829,168.829,0,0,1,18.4-9.905,1.515,1.515,0,1,1,1.418,2.679c-.125.067-4.165,2.119-11.771,6.717-7.108,4.3-17.641,10.993-22.437,15.481A1.5,1.5,0,0,1,4059.8,9066.891Z"
            fill="#00a6dd"
          />
          <path
            id="Path_125"
            data-name="Path 125"
            d="M4043.332,9039.913a1.516,1.516,0,0,1-1.3-2.6c4.408-4.125,11.05-8.11,17.278-11.348,8.309-4.317,15.88-7.306,16.424-7.519a1.517,1.517,0,0,1,1.106,2.824c-.13.051-5.414,2.775-13.637,7.022-6.137,3.17-14.885,7.288-19.1,11.232A1.511,1.511,0,0,1,4043.332,9039.913Z"
            fill="#00a6dd"
          />
          <path
            id="Path_126"
            data-name="Path 126"
            d="M4039.554,9060.273a1.516,1.516,0,0,1-1.228-2.663c3.835-3.158,10.747-7.792,17.106-11.884,7.209-4.639,13.707-8.579,14.207-8.883a1.517,1.517,0,0,1,1.571,2.595c-.121.073-5.475,3.792-12.7,8.434-6.394,4.108-14.493,8.979-18.255,12.079A1.528,1.528,0,0,1,4039.554,9060.273Z"
            fill="#00a6dd"
          />
        </g>
        <path
          id="Path_127"
          data-name="Path 127"
          d="M4097.191,8953.155s-.908-8.4-1.361-10.211-1.135-2.95-2.269-1.588-3.4,8.849-5.219,9.075-10.665-.907-12.707-.227,9.076,7.261,9.53,7.942-6.58,10.891-5.446,10.891,5.9-2.95,8.623-4.312,1.815-2.269,4.084,1.135,3.857,7.035,4.992,6.354,1.134-11.119,1.815-11.572,9.53-1.589,8.4-2.723S4097.191,8953.155,4097.191,8953.155Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#d4daea"
        />
        <path
          id="Path_128"
          data-name="Path 128"
          d="M4268.684,8971.6s-.892-8.258-1.339-10.043-1.116-2.9-2.231-1.562-3.348,8.7-5.133,8.927-10.49-.893-12.5-.224,8.927,7.142,9.373,7.812-6.472,10.712-5.356,10.712,5.8-2.9,8.481-4.239,1.785-2.232,4.017,1.114,3.794,6.919,4.909,6.249,1.116-10.935,1.786-11.382,9.373-1.562,8.257-2.678S4268.684,8971.6,4268.684,8971.6Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#d4daea"
        />
        <path
          id="Path_129"
          data-name="Path 129"
          d="M4193.692,8955.065a13.716,13.716,0,0,0-6.94,3.6c-3.435,3.147-5.092,6.492-4.923,9.943.183,3.729,1.421,6.255,5.359,9.21,7.128,5.349,15.5-2.681,16.323-3.546,3.965-4.189,7.371-8.891.595-16.641A14.418,14.418,0,0,0,4193.692,8955.065Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#fff"
        />
      </g>
    </IconSvg>
  );
};
