import React from 'react';
import { IconSvg } from '../icon-props';

export const IconCareersSearch = (props: React.CSSProperties) => {
  return (
    <IconSvg
      width={props.width || '241.641'}
      height={props.height || '192.605'}
      viewBox="0 0 241.641 192.605"
      fill={props.fill}
      style={{ ...props }}
    >
      <g id="Group_735" data-name="Group 735" transform="translate(-673.531 -880.292)">
        <path
          id="Path_48"
          data-name="Path 48"
          d="M4522.573,9033.391c-.84-.3-1.835.217-4.576,1.865l-.336.2c-1.6.96-9.278,5.761-17.407,10.843-9.087,5.683-19.387,12.122-23.247,14.48-2.971,1.814-5.559,3.412-7.733,4.754-3.183,1.964-5.483,3.383-6.809,4.143a2.946,2.946,0,0,0-1.547,3.941c.8,2.2,5.352,8.527,6.434,9.184.756.456,1.944.033,5.778-2.067,4.118-2.258,23.922-14.292,30.435-18.562,3.016-1.977,7.18-4.649,11.207-7.234,4.668-3,9.077-5.826,10.459-6.783.224-.155.46-.316.705-.482,3.13-2.125,4.781-3.593,3.957-4.811-1.849-2.736-3.222-4.606-3.235-4.624l-.082-.125a17.547,17.547,0,0,0-3.536-4.474A1.966,1.966,0,0,0,4522.573,9033.391Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#d4daea"
        />
        <path
          id="Path_50"
          data-name="Path 50"
          d="M4576.231,8922.671s-34.616,10.029-44.322,42.7,5.177,54.027,18.764,66.321,35.587,22.969,67.615,19.411,48.2-43.028,48.851-59.527-8.088-43.352-25.558-55.968S4597.907,8916.848,4576.231,8922.671Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#d4daea"
        />
        <path
          id="Path_51"
          data-name="Path 51"
          d="M4585.182,8932.916s-30.411,4.1-40.979,32.567,3.019,64.056,39.469,73.33,64.272-10.784,69.448-33.861,0-45.508-19.2-60.821S4585.182,8932.916,4585.182,8932.916Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#fff"
        />
        <path
          id="Path_98"
          data-name="Path 98"
          d="M4599.066,9003.579c-5.821,0-11.079-3.146-15.205-9.093a36.76,36.76,0,0,1-4.253-8.294,6.7,6.7,0,0,1-4.446-1.356,6.967,6.967,0,0,1-2.258-5.515c-.054-2.436.616-4.192,1.991-5.224a5.862,5.862,0,0,1,4.271-.887c.06-7.287,4.428-13.2,6-15.087a10.951,10.951,0,0,1-2.5-5.034,9.189,9.189,0,0,1,1.939-8.255c3.594-4.227,10.773-5.828,15.924-5.521,4.919.288,6.993,2.271,9,4.188a14.572,14.572,0,0,0,5.541,3.716c2.818,1.006,7.823,3.48,9.685,8.742,1.664,4.7.492,10.53-3.484,17.337a3.782,3.782,0,0,1,1.9.705c1.782,1.285,2.528,3.948,2.048,7.307a5.376,5.376,0,0,1-2.273,3.934,7.077,7.077,0,0,1-4.746.858,36.4,36.4,0,0,1-4.079,8.3C4610.1,9000.4,4604.893,9003.579,4599.066,9003.579Zm-19.317-18.989a.865.865,0,0,1,.824.609c.051.169,5.64,16.668,18.352,16.668,5.287,0,9.68-2.95,13.416-8.512a37.55,37.55,0,0,0,4.268-8.9.862.862,0,0,1,1.048-.6,4.386,4.386,0,0,0,3.74-.271,3.684,3.684,0,0,0,1.513-2.734c.381-2.663.276-4.307-.949-5.189a2.18,2.18,0,0,0-2.4-.308.863.863,0,0,1-1.246-.718c-.007-.118-.82-11.937-5.95-14.36-3.946-1.862-5.039-1.45-7.679-.456-.923.347-1.968.741-3.345,1.134-4.008,1.146-8.4,2.428-12.5,0-.375-.223-1.023-.777-1.358-1.037-1.546,1.9-7.414,7.252-7.045,14.263a.862.862,0,0,1-1.122.867,3.941,3.941,0,0,0-3.4.437c-.9.678-1.336,1.957-1.295,3.8a5.351,5.351,0,0,0,1.629,4.221,4.821,4.821,0,0,0,3.342,1.1A.863.863,0,0,1,4579.749,8984.59Zm28.78-28.345a12.143,12.143,0,0,1,5.122,1.474c4.59,2.167,5.643,10.85,6.162,14.657,3.684-6.324,3.517-11.977,2.034-16.167-1.543-4.359-5.753-5.583-8.843-6.686-2.983-1.066-4.177-3.765-5.662-5.184-1.944-1.857-2.626-3.054-6.912-3.306-4.736-.28-11.3,1.141-14.509,4.918a7.426,7.426,0,0,0-1.57,6.761c.589,2.632,2.629,4.2,4.713,5.438,3.625,2.153,6.957,1.324,10.618.278,1.309-.374,2.319-.755,3.21-1.09A21.434,21.434,0,0,1,4608.529,8956.245Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#00afdf"
        />
        <path
          id="Path_99"
          data-name="Path 99"
          d="M4598.992,8988.18a3.432,3.432,0,0,1-2.206-.83c-.683-.567-.554-1.694-.3-3.938.341-2.981,1.54-7.935-.759-9.706-4.557-3.512-10.388-1.749-10.46-1.733a.862.862,0,1,1-.377-1.683c.335-.076,8.241-1.794,11.838,2.317,2.413,2.758,1.846,7.718,1.471,11a16.877,16.877,0,0,0-.185,2.515,1.609,1.609,0,0,0,2.169-.2.862.862,0,0,1,1.22,1.219A3.4,3.4,0,0,1,4598.992,8988.18Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#00afdf"
        />
        <path
          id="Path_100"
          data-name="Path 100"
          d="M4603,8972.935a.862.862,0,0,1-.421-1.614c.236-.132,5.85-3.2,10.939.023a.863.863,0,0,1-.923,1.458,8.6,8.6,0,0,0-9.175.024A.859.859,0,0,1,4603,8972.935Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#00afdf"
        />
        <path
          id="Path_101"
          data-name="Path 101"
          d="M4580.907,9014.45c-6.537,0-10.592-1.164-12.064-3.469a4.4,4.4,0,0,1,.066-4.565c1.414-2.827.141-7.8-1.089-12.607a52.546,52.546,0,0,1-1.512-7.189c-.454-4.427.914-7.232,2.5-10.479a52.745,52.745,0,0,0,2.266-5.148c.975-2.665.987-5.607,1-8.453.017-3.9.032-7.581,2.478-10.222,4.247-4.588,8.484-3.358,8.662-3.3a.862.862,0,0,1-.5,1.651h0c-.137-.039-2.142-1.854-5.621,1.9-1.989,2.147-3.281,6.271-3.3,9.978a35.7,35.7,0,0,1-1.514,10.527c-.764,2.088-2.048,3.426-2.785,4.936-1.587,3.258-1.876,4.5-1.472,8.436a46.086,46.086,0,0,0,.984,7.044c1.309,5.113,2.662,10.4.961,13.806a2.788,2.788,0,0,0-.156,2.865c1.2,1.883,7.279,2.382,13.954,2.183.48-.035-.2.716-.182,1.192a.861.861,0,0,1-.835.888Q4581.8,9014.452,4580.907,9014.45Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#00afdf"
        />
        <path
          id="Path_102"
          data-name="Path 102"
          d="M4616.386,9014.083a8.3,8.3,0,0,1-2.161-.215.863.863,0,0,1,.486-1.655c2.458.723,12.651-1.115,14.3-1.938l.365-.18c1.493-.722,1.665-1.021-.326-5.253-2.786-5.919-.031-13.776,1.329-17.72,1.554-4.508-.234-9.557-3.39-13.573-3.6-4.582-2.812-8.633-2.812-8.831,0-.476-.3-1.183.034-1.521.631-.638,1.4-1.812.862.861a15.059,15.059,0,0,0,3.273,8.426c3.525,4.486,5.027,12.561,3.28,17.629-1.638,4.75-2.807,9.079-.507,13.966,2.325,4.94,1.573,6.328-.992,7.569l-.345.169C4628.135,9012.639,4620.612,9014.083,4616.386,9014.083Zm8.686-50.024h0Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#00afdf"
        />
        <path
          id="Path_103"
          data-name="Path 103"
          d="M4633.795,9024.164a.858.858,0,0,1-.621-.264c-7.885-8.188-25.3-11.66-25.472-11.694a.863.863,0,0,1-.7-.847l.007-11.235a.862.862,0,0,1,.862-.861h0a.863.863,0,0,1,.862.863l-.006,10.532c3.433.745,18.251,4.323,25.688,12.046a.862.862,0,0,1-.622,1.46Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#00afdf"
        />
        <path
          id="Path_104"
          data-name="Path 104"
          d="M4564.181,9024.479a.862.862,0,0,1-.575-1.506c8-7.142,22.671-11.515,25.958-12.431l.043-10.329a.863.863,0,0,1,.862-.859h0a.863.863,0,0,1,.858.866l-.045,10.985a.862.862,0,0,1-.644.831c-.173.045-17.346,4.6-25.887,12.224A.862.862,0,0,1,4564.181,9024.479Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#00afdf"
        />
        <path
          id="Path_105"
          data-name="Path 105"
          d="M4598.909,9026.05c-11.528,0-17.776-10.821-18.036-11.281a.862.862,0,0,1,1.5-.849c.059.1,6.027,10.405,16.535,10.405s16.634-10.46,16.695-10.565a.862.862,0,0,1,1.5.856C4616.834,9015.083,4610.434,9026.05,4598.909,9026.05Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#00afdf"
        />
        <path
          id="Path_106"
          data-name="Path 106"
          d="M4599.273,8994.526a10.254,10.254,0,0,1-5.559-2.095.861.861,0,0,1-.235-1.2c.265-.4.652-.2,1.047.063.229.151,5.379,3.259,8.829.063.349-.324.856-.75,1.193-.413a.864.864,0,0,1,0,1.22A7.782,7.782,0,0,1,4599.273,8994.526Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#00afdf"
        />
        <path
          id="Path_107"
          data-name="Path 107"
          d="M4606.013,8976.553s.076,3,2.365,2.457a2.046,2.046,0,0,0,.767-3.755C4607.974,8974.464,4605.929,8975.285,4606.013,8976.553Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#00afdf"
        />
        <path
          id="Path_108"
          data-name="Path 108"
          d="M4588.533,8977.789a2.042,2.042,0,0,0,2.424,1.225,1.811,1.811,0,0,0,1.482-2.816,1.759,1.759,0,0,0-2.424-1.1C4588.937,8975.34,4588.129,8975.83,4588.533,8977.789Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#00afdf"
        />
        <g id="Group_21" data-name="Group 21" transform="translate(-3784.472 -8012.74)">
          <g id="Group_20" data-name="Group 20">
            <path
              id="Path_109"
              data-name="Path 109"
              d="M4625.574,8912.143a.974.974,0,0,1-.191-.081,1.111,1.111,0,0,1-.454-1.5h0c.024-.045,2.424-4.662,4.22-16.579a1.112,1.112,0,0,1,1.266-.934c.609.093,1.876,1.3,1.784,1.912-1.866,12.38-5.2,16.459-5.308,16.654A1.113,1.113,0,0,1,4625.574,8912.143Z"
              fill="#00a8e9"
            />
            <path
              id="Path_110"
              data-name="Path 110"
              d="M4662.4,8928.094a1.113,1.113,0,0,1-.332-1.949h0c.041-.03,4.157-3.214,10.791-13.274a1.107,1.107,0,0,1,1.542-.316c.513.338,1.267,1.732.929,2.245-6.891,10.452-11.746,12.992-11.923,13.126A1.11,1.11,0,0,1,4662.4,8928.094Z"
              fill="#00a8e9"
            />
            <path
              id="Path_111"
              data-name="Path 111"
              d="M4681.161,8960.7a1.113,1.113,0,0,1,.313-2.173c.1,0,5.3-.208,16.5-4.492a1.113,1.113,0,0,1,1.438.641c.219.575.432,2.823-.142,3.043-11.694,4.473-17.531,3.029-17.752,3.033A1.11,1.11,0,0,1,4681.161,8960.7Z"
              fill="#00a8e9"
            />
          </g>
        </g>
        <path
          id="Path_112"
          data-name="Path 112"
          d="M4577.686,9048.36c-16.408-5.769-31.271-16.029-39.262-28.241-14.227-21.742-15.738-58.022,9.486-82.435,25.339-24.521,61.181-18.107,62.075-17.926,21.56,4.254,38.258,14.23,48.527,30.2,11.456,17.813,13.032,39.51,9.921,52.625-3.143,13.252-11.592,25.944-21.568,35.156a59.955,59.955,0,0,1-38.79,15.6A83.455,83.455,0,0,1,4577.686,9048.36Zm43.075-122.261a89.462,89.462,0,0,0-12.375-3.352l-.018,0c-.338-.072-34.106-6.867-58.611,16.848a61.064,61.064,0,0,0-18.73,41.072c-.767,13.636,2.739,27.487,9.619,38,12.381,18.92,41.476,32.727,66.85,31.759,13.515-.516,25.831-6.176,36.22-15.77,9.592-8.858,17.337-20.185,20.36-32.928a66.017,66.017,0,0,0-8.753-49.24C4647.5,8940.33,4635.693,8931.35,4620.761,8926.1Zm-12.118-4.653.006,0Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#151f35"
        />
        <path
          id="Path_113"
          data-name="Path 113"
          d="M4576.77,9038.813c-3.814-1.342-6.208-3.382-9.6-5.284a55.483,55.483,0,0,1-24.559-68.224c11.207-29.374,40.1-35.138,40.4-35.19l.019,0c38.438-6.144,60.991,19.063,67.938,30.194,8.17,13.091,13.343,47.051-9.921,68.369a58.194,58.194,0,0,1-39.685,14.882C4593.57,9043.484,4584.329,9041.471,4576.77,9038.813Zm8.23-105.058c-.611.112-29.316,4.727-39.911,32.5a52.825,52.825,0,0,0,23.376,64.96c3.969,2.226,10.3,4.151,14.109,5.49,26.558,9.34,46.178-1.5,55.89-10.4,22.113-20.265,16.794-51.909,9.031-64.347C4640.83,8951.272,4621.86,8927.874,4585,8933.756Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#151f35"
        />
        <path
          id="Path_114"
          data-name="Path 114"
          d="M4525.97,9035.432a1.326,1.326,0,0,1-.286-2.362l15.455-10.112a1.327,1.327,0,1,1,1.454,2.222l-15.455,10.112A1.324,1.324,0,0,1,4525.97,9035.432Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#151f35"
        />
        <path
          id="Path_115"
          data-name="Path 115"
          d="M4529.171,9041.473a1.328,1.328,0,0,1-.3-2.351l16.174-10.979a1.328,1.328,0,0,1,1.491,2.2l-16.174,10.979A1.323,1.323,0,0,1,4529.171,9041.473Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#151f35"
        />
        <path
          id="Path_116"
          data-name="Path 116"
          d="M4466.726,9085.432a4,4,0,0,1-.752-.359c-1.829-1.11-6.647-8.049-7.553-10.548a5.542,5.542,0,0,1,2.723-7.149c1.289-.739,3.573-2.149,6.735-4.1,2.177-1.344,4.769-2.943,7.743-4.761,3.849-2.351,14.143-8.787,23.224-14.465,8.494-5.31,15.829-9.9,17.448-10.868l.335-.2c3.272-1.968,5.432-3.269,7.935-1.515,1.955,1.369,3.917,4.642,4.276,5.258.252.346,1.558,2.144,3.253,4.651,2.468,3.651-.565,6.765-3.233,8.577-.238.161-.468.316-.685.467-1.42.985-5.848,3.827-10.536,6.836-4.022,2.581-8.181,5.25-11.186,7.22-6.546,4.292-27.9,16.315-32.048,18.589C4470.818,9085.029,4468.67,9086.115,4466.726,9085.432Zm55.847-52.041c-.84-.3-1.835.217-4.576,1.865l-.336.2c-1.6.96-9.278,5.761-17.407,10.843-9.087,5.683-19.387,12.122-23.247,14.48-2.971,1.814-5.559,3.412-7.733,4.754-3.183,1.964-5.483,3.383-6.809,4.143a2.946,2.946,0,0,0-1.547,3.941c.8,2.2,5.352,8.527,6.434,9.184.756.456,1.944.033,5.778-2.067,4.118-2.258,23.922-14.292,30.435-18.562,3.016-1.977,7.18-4.649,11.207-7.234,4.668-3,9.077-5.826,10.459-6.783.224-.155.46-.316.705-.482,3.13-2.125,4.781-3.593,3.957-4.811-1.849-2.736-3.222-4.606-3.235-4.624l-.082-.125a17.547,17.547,0,0,0-3.536-4.474A1.966,1.966,0,0,0,4522.573,9033.391Z"
          transform="translate(-3784.472 -8012.74)"
          fill="#151f35"
        />
      </g>
    </IconSvg>
  );
};
