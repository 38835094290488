import { Box, Grid, Typography, useTheme } from '@mui/material';
import { graphql } from 'gatsby';
import React from 'react';
import { IconCareersMessage } from '../assets/icons/careers/careers-message';
import { IconCareersSearch } from '../assets/icons/careers/careers-search';
import { IconCareers } from '../assets/icons/careers/carrers';
import { IconCareersPlane } from '../assets/icons/careers/carrers-plane';
import { IconWave } from '../assets/icons/how-it-works/wave';
import { ImageCareersBg } from '../assets/images';
import { LL_BACKGROUND_COLOR } from '../colors';
import CareerInfoCard from '../components/careers/career-info-card';
import { JobCard } from '../components/careers/job-card';
import { DecorativeHeading } from '../components/decorative-heading';
import CareerForm from '../components/forms/career-form';
import { HeroSection } from '../components/hero-section';
import Layout from '../components/layout';
import { Absolute } from '../components/primitives/absolute';
import { Width80Box } from '../components/primitives/Width80Box';
import { PositionsQueryQuery } from '../../graphql-types';

export interface CareersProps {
  data: CraftGql_Positions_Positions_Entry
}

const careerCards = [
  {
    details:
      'Be part of something really awesome. A company that is digitally transforming the real estate industry while fostering and celebrating personal growth among our team of go-getters.',
    heading: 'Opportunity',
    Icon: <IconCareersPlane />
  },
  {
    details:
      'We only surround ourselves with smart, hardworking and kind individuals who have a good sense of humour and love challenging the status quo.',
    heading: 'People',
    Icon: <IconCareersSearch />
  },
  {
    details:
      'It’s what separates us from the rest. Å collaborative office environment that encourages you to rock out on your strengths and consistently raise the bar.',
    heading: 'Culture',
    Icon: <IconCareersMessage />
  }
];

// const openings = [
//   {
//     jobTitle: 'React JS Developer',
//     department: 'Digital Solutions',
//     location: 'Mornington'
//   },
//   {
//     jobTitle: 'React JS Developer',
//     department: 'Digital Solutions',
//     location: 'Mornington'
//   },
//   {
//     jobTitle: 'React JS Developer',
//     department: 'Digital Solutions',
//     location: 'Mornington'
//   }
// ];

const Careers = (props: CareersProps) => {
  const resultData = props.data.allCraftGqlPositionsDefaultEntry.nodes
  console.log(props,'$$$')
  const theme = useTheme();
  return (
    <Box>
      <HeroSection
        title={
          <div>
            It all starts with <br />
            our amazing team
          </div>
        }
        icon={<IconCareers />}
        bgImage={ImageCareersBg}
      />

      <Width80Box
        paddingBottom={{ md: '60px', xs: '30px' }}
        paddingTop={{ xs: '30px', md: '90px' }}
        px={{ xs: '20px', md: '0px' }}
      >
        <Typography color="primary.main" fontFamily="Tropiline" textAlign="center" variant="h3">
          Why work with us
        </Typography>
        <Grid container justifyContent="center">
          {careerCards.map((item, index) => (
            <Grid item xs={12} md={4} key={item.heading + index}>
              <CareerInfoCard
                theme={theme}
                heading={item.heading}
                details={item.details}
                Icon={item.Icon}
              />
            </Grid>
          ))}
        </Grid>
      </Width80Box>
      <IconWave width="100%" height="auto" marginBottom="-5px" />
      <Box bgcolor={LL_BACKGROUND_COLOR}>
        <Width80Box py={{ xs: '30px', md: '50px' }} px={{ xs: '20px', md: '0px' }}>
          <Typography textAlign="center" variant="h3" fontWeight={500}>
            Open positions
          </Typography>
          <Grid
            container
            spacing="60px"
            justifyContent="space-between"
            py={{ xs: '30px', md: '50px' }}
          >
            {resultData.map((job, index) => (
              <Grid item xs={12} lg={6} xl={4} key={index}>
                <Box minWidth="320px">
                  <JobCard
                    jobTitle={job.title}
                    department={job.positionDepartment}
                    location={job.positionLocation}
                    href={job.slug}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Width80Box>
        <DecorativeHeading text="Want to be a part of our team?" />
        <Box
          sx={{
            paddingY: '60px',
            paddingX: '20px',
            width: '60%',
            maxWidth: '1300px',
            marginX: 'auto',
            [theme.breakpoints.down('md')]: {
              width: '100%'
            }
          }}
        >
          <CareerForm theme={theme} />
        </Box>
        <Box bgcolor={LL_BACKGROUND_COLOR} width="100%">
          <Box position="relative">
            <Absolute
              height="250px"
              width="100%"
              zIndex={-1}
              bgcolor={LL_BACKGROUND_COLOR}
            ></Absolute>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};


// export const careersQuery = graphql`
// query PositionsQuery($slug: String) {
//   craftGqlPositionsDefaultEntry(slug: { eq: $slug }) {
//     slug
//         positionDepartment
//         positionLocation
//         richText
//         title
//     }
//   }`

export const positionsQuery = graphql`
query PositionsEntries {
  allCraftGqlPositionsDefaultEntry {
    nodes {
      positionDepartment
      positionLocation
      richText
      title
      slug
    }
  }
}
`

  
Careers.Layout = Layout;

export default Careers;


