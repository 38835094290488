import React from 'react';
import { IconSvg } from '../icon-props';

export const IconCareers = (props: React.CSSProperties) => {
  return (
    <IconSvg
      width={props.width || '132.573'}
      height={props.height || '92.908'}
      viewBox="0 0 132.573 92.908"
      fill={props.fill}
      style={{ ...props }}
    >
      <g id="Group_23" data-name="Group 23" transform="translate(-4159.283 -8399.917)">
        <path
          id="Path_134"
          data-name="Path 134"
          d="M4226.132,8449.952c-11.742,0-13.947-10.534-14.128-11.523-.113-.551-1.493-7.753,2.839-12.9a15.793,15.793,0,0,1,10.708-5.228,15.24,15.24,0,0,1,11.267,3.651c3.861,3.335,5.317,9.8,3.542,15.729-1.7,5.681-5.837,9.354-11.347,10.076A22.318,22.318,0,0,1,4226.132,8449.952Zm-10.99-12.074c.066.42,1.752,10.256,13.456,8.719,4.211-.552,7.386-3.406,8.711-7.831a12.869,12.869,0,0,0-3.591-12.556,11.945,11.945,0,0,0-8.932-2.886,12.727,12.727,0,0,0-8.523,4.1c-3.4,4.037-1.15,10.306-1.137,10.366Z"
          fill="#fff"
        />
        <path
          id="Path_135"
          data-name="Path 135"
          d="M4268.255,8429c-9.611,0-12.611-8.678-12.891-9.562-.178-.521-2.393-7.317,1.168-12.831a15.4,15.4,0,0,1,9.72-6.367,14.857,14.857,0,0,1,11.347,2.154c4.144,2.756,6.346,8.835,5.354,14.784-.951,5.7-4.5,9.765-9.75,11.139A19.537,19.537,0,0,1,4268.255,8429Zm-10.62-10.34c.114.395,3.7,9.473,14.761,6.575,3.977-1.042,5.831-4.586,6.566-9,.78-4.676-.044-9.143-3.127-11.192a11.577,11.577,0,0,0-8.934-1.687c-3.191.664-6.911,2.734-8.465,5.14-2.77,4.29-.848,10.021-.828,10.078Z"
          fill="#fff"
        />
        <path
          id="Path_136"
          data-name="Path 136"
          d="M4199.78,8492h-.045c-.879-.024-1.572-.109-1.547-.989.023-.848.586-20.858,1.546-26.821,1.111-6.9,7.014-10.368,19.739-11.6,13.186-1.28,22.37.234,27.3,4.5a11.491,11.491,0,0,1,4.027,8.468c.568,11.962.385,25,.383,25.131-.013.871-.941,1-1.81,1h-.024c-.88-.013-1.364-.172-1.352-1.052,0-.129.184-13.072-.379-24.933-.123-2.58-1.442-3.905-3.29-5.5-2.918-2.525-9.608-5.224-24.907-3.738-11.156,1.083-15.692,2.968-16.54,8.235-.927,5.756-1.5,26.2-1.507,26.4C4201.348,8491.958,4200.639,8492,4199.78,8492Z"
          fill="#fff"
        />
        <path
          id="Path_137"
          data-name="Path 137"
          d="M4210.537,8492.825a1.592,1.592,0,0,1-.782-.207c-.677-.382-1.229-.972-.989-1.683.219-1.272.512-10.221.68-18.129.019-.879.679-1.25,1.556-1.23s1.648.419,1.629,1.3c-.39,18.328-.738,18.945-.968,19.353A1.041,1.041,0,0,1,4210.537,8492.825Zm-1.648-2.163h0Z"
          fill="#fff"
        />
        <path
          id="Path_138"
          data-name="Path 138"
          d="M4239.215,8490.748a1.587,1.587,0,0,1-.511-.085c-.8-.271-1.368-.405-1.15-1.21.2-1.3-.022-11.354-.245-18.137-.028-.88,1.183-1.114,2.062-1.144s1.093.16,1.123,1.039c.386,11.789.422,18.237.107,19.167C4240.376,8491.042,4239.879,8490.748,4239.215,8490.748Zm-1.631-1.392c-.007.02-.015.043-.022.07C4237.568,8489.4,4237.576,8489.38,4237.584,8489.356Z"
          fill="#fff"
        />
        <path
          id="Path_139"
          data-name="Path 139"
          d="M4290.341,8480.616c-.871,0-1.66-.074-1.671-.948-.158-13.13-1.74-33.226-2.158-35.6l-.068-.4c-.556-3.284-.293-9.079-14.376-9.253-1.414-.017-4.158-.233-5.42-.255-13.485-.224-16.047-.212-17.925,4.988-2.436,6.746-1.716,18.962-1.712,19.081a1.593,1.593,0,0,1-3.185.1c-.016-.511-.374-12.611,2.268-19.926,2.728-7.555,8.771-7.455,22.016-7.236q1.886.031,4,.061c16.74.206,18.044,7.906,18.671,11.607l.065.376c.6,3.389.971,33.039,1.012,36.409.01.88-.616.977-1.5.986Z"
          fill="#fff"
        />
        <path
          id="Path_140"
          data-name="Path 140"
          d="M4279.9,8479.837a1.536,1.536,0,0,1-1.667-1.061c-.4-2.011.48-21.973.761-28.043a1.6,1.6,0,0,1,1.664-1.518c.879.04,1.152-.029,1.112.851-.449,9.718-.661,26.534-.411,28.1a1.308,1.308,0,0,1-1.148,1.641A1.542,1.542,0,0,1,4279.9,8479.837Z"
          fill="#fff"
        />
        <path
          id="Path_141"
          data-name="Path 141"
          d="M4257.092,8479.235a1.6,1.6,0,0,1-.263-.022c-.868-.144-1.635-.538-1.49-1.406.27-1.624.13-18.378-.022-28.557-.013-.88-.147-1.353.732-1.367h.024c.869,0,2.416.448,2.429,1.319.041,2.725.386,26.687-.021,29.129C4258.351,8479.11,4257.857,8479.235,4257.092,8479.235Z"
          fill="#fff"
        />
        <path
          id="Path_142"
          data-name="Path 142"
          d="M4182.883,8430.793a19.538,19.538,0,0,1-4.948-.683c-5.245-1.375-8.8-5.436-9.75-11.14-.991-5.949,1.21-12.028,5.355-14.784a14.862,14.862,0,0,1,11.347-2.154,15.4,15.4,0,0,1,9.719,6.367c3.563,5.515,1.346,12.313,1.169,12.832C4195.494,8422.118,4192.492,8430.793,4182.883,8430.793Zm-1.178-25.906a11.394,11.394,0,0,0-6.4,1.952c-3.084,2.05-4.367,5.952-3.587,10.628.736,4.412,3.438,7.539,7.415,8.581,4.661,1.222,8.41.492,11.14-2.169a8.385,8.385,0,0,0,2.46-3.589l.027-.085c.02-.057,2.466-6.221-.3-10.51-1.554-2.405-5.025-3.879-8.216-4.544A12.424,12.424,0,0,0,4181.705,8404.887Z"
          fill="#fff"
        />
        <path
          id="Path_143"
          data-name="Path 143"
          d="M4161.146,8478.59h-.022c-.88-.012-1.853-.187-1.841-1.066.041-2.972.414-29.126,1.012-32.514l.064-.377c.628-3.7,1.933-11.4,18.672-11.606q2.114-.026,4-.061c13.244-.219,19.289-.318,22.017,7.236a47.142,47.142,0,0,1,2.266,16.057,1.593,1.593,0,1,1-3.182-.15c0-.08.345-8.108-2.08-14.824-1.878-5.2-6.126-3.973-19.613-3.748-1.261.02-2.6.042-4.011.06-14.081.174-15.013,5.67-15.569,8.954l-.069.4c-.467,2.65-.209,22.616-.318,30.619C4162.456,8478.439,4162.015,8478.59,4161.146,8478.59Z"
          fill="#fff"
        />
        <path
          id="Path_144"
          data-name="Path 144"
          d="M4171.414,8477.686a1.653,1.653,0,0,1-.315-.03c-.862-.173-1.488-.745-1.315-1.608.222-1.313-.256-13.021-.819-23.364a1.593,1.593,0,0,1,3.181-.174c.281,5.159,1.165,22.147.762,24.163C4172.756,8477.429,4172.157,8477.686,4171.414,8477.686Z"
          fill="#fff"
        />
        <path
          id="Path_145"
          data-name="Path 145"
          d="M4193.857,8477.031c-.765,0-1.069-.026-1.2-.806-.408-2.443-.062-22.911-.021-25.237a1.593,1.593,0,1,1,3.185.055c-.152,8.673-.292,23.038-.022,24.66.145.867-.812,1.162-1.679,1.307A1.624,1.624,0,0,1,4193.857,8477.031Z"
          fill="#fff"
        />
      </g>
    </IconSvg>
  );
};

<svg
  xmlns="http://www.w3.org/2000/svg"
  width="132.573"
  height="92.908"
  viewBox="0 0 132.573 92.908"
>
  <g id="Group_23" data-name="Group 23" transform="translate(-4159.283 -8399.917)">
    <path
      id="Path_134"
      data-name="Path 134"
      d="M4226.132,8449.952c-11.742,0-13.947-10.534-14.128-11.523-.113-.551-1.493-7.753,2.839-12.9a15.793,15.793,0,0,1,10.708-5.228,15.24,15.24,0,0,1,11.267,3.651c3.861,3.335,5.317,9.8,3.542,15.729-1.7,5.681-5.837,9.354-11.347,10.076A22.318,22.318,0,0,1,4226.132,8449.952Zm-10.99-12.074c.066.42,1.752,10.256,13.456,8.719,4.211-.552,7.386-3.406,8.711-7.831a12.869,12.869,0,0,0-3.591-12.556,11.945,11.945,0,0,0-8.932-2.886,12.727,12.727,0,0,0-8.523,4.1c-3.4,4.037-1.15,10.306-1.137,10.366Z"
      fill="#fff"
    />
    <path
      id="Path_135"
      data-name="Path 135"
      d="M4268.255,8429c-9.611,0-12.611-8.678-12.891-9.562-.178-.521-2.393-7.317,1.168-12.831a15.4,15.4,0,0,1,9.72-6.367,14.857,14.857,0,0,1,11.347,2.154c4.144,2.756,6.346,8.835,5.354,14.784-.951,5.7-4.5,9.765-9.75,11.139A19.537,19.537,0,0,1,4268.255,8429Zm-10.62-10.34c.114.395,3.7,9.473,14.761,6.575,3.977-1.042,5.831-4.586,6.566-9,.78-4.676-.044-9.143-3.127-11.192a11.577,11.577,0,0,0-8.934-1.687c-3.191.664-6.911,2.734-8.465,5.14-2.77,4.29-.848,10.021-.828,10.078Z"
      fill="#fff"
    />
    <path
      id="Path_136"
      data-name="Path 136"
      d="M4199.78,8492h-.045c-.879-.024-1.572-.109-1.547-.989.023-.848.586-20.858,1.546-26.821,1.111-6.9,7.014-10.368,19.739-11.6,13.186-1.28,22.37.234,27.3,4.5a11.491,11.491,0,0,1,4.027,8.468c.568,11.962.385,25,.383,25.131-.013.871-.941,1-1.81,1h-.024c-.88-.013-1.364-.172-1.352-1.052,0-.129.184-13.072-.379-24.933-.123-2.58-1.442-3.905-3.29-5.5-2.918-2.525-9.608-5.224-24.907-3.738-11.156,1.083-15.692,2.968-16.54,8.235-.927,5.756-1.5,26.2-1.507,26.4C4201.348,8491.958,4200.639,8492,4199.78,8492Z"
      fill="#fff"
    />
    <path
      id="Path_137"
      data-name="Path 137"
      d="M4210.537,8492.825a1.592,1.592,0,0,1-.782-.207c-.677-.382-1.229-.972-.989-1.683.219-1.272.512-10.221.68-18.129.019-.879.679-1.25,1.556-1.23s1.648.419,1.629,1.3c-.39,18.328-.738,18.945-.968,19.353A1.041,1.041,0,0,1,4210.537,8492.825Zm-1.648-2.163h0Z"
      fill="#fff"
    />
    <path
      id="Path_138"
      data-name="Path 138"
      d="M4239.215,8490.748a1.587,1.587,0,0,1-.511-.085c-.8-.271-1.368-.405-1.15-1.21.2-1.3-.022-11.354-.245-18.137-.028-.88,1.183-1.114,2.062-1.144s1.093.16,1.123,1.039c.386,11.789.422,18.237.107,19.167C4240.376,8491.042,4239.879,8490.748,4239.215,8490.748Zm-1.631-1.392c-.007.02-.015.043-.022.07C4237.568,8489.4,4237.576,8489.38,4237.584,8489.356Z"
      fill="#fff"
    />
    <path
      id="Path_139"
      data-name="Path 139"
      d="M4290.341,8480.616c-.871,0-1.66-.074-1.671-.948-.158-13.13-1.74-33.226-2.158-35.6l-.068-.4c-.556-3.284-.293-9.079-14.376-9.253-1.414-.017-4.158-.233-5.42-.255-13.485-.224-16.047-.212-17.925,4.988-2.436,6.746-1.716,18.962-1.712,19.081a1.593,1.593,0,0,1-3.185.1c-.016-.511-.374-12.611,2.268-19.926,2.728-7.555,8.771-7.455,22.016-7.236q1.886.031,4,.061c16.74.206,18.044,7.906,18.671,11.607l.065.376c.6,3.389.971,33.039,1.012,36.409.01.88-.616.977-1.5.986Z"
      fill="#fff"
    />
    <path
      id="Path_140"
      data-name="Path 140"
      d="M4279.9,8479.837a1.536,1.536,0,0,1-1.667-1.061c-.4-2.011.48-21.973.761-28.043a1.6,1.6,0,0,1,1.664-1.518c.879.04,1.152-.029,1.112.851-.449,9.718-.661,26.534-.411,28.1a1.308,1.308,0,0,1-1.148,1.641A1.542,1.542,0,0,1,4279.9,8479.837Z"
      fill="#fff"
    />
    <path
      id="Path_141"
      data-name="Path 141"
      d="M4257.092,8479.235a1.6,1.6,0,0,1-.263-.022c-.868-.144-1.635-.538-1.49-1.406.27-1.624.13-18.378-.022-28.557-.013-.88-.147-1.353.732-1.367h.024c.869,0,2.416.448,2.429,1.319.041,2.725.386,26.687-.021,29.129C4258.351,8479.11,4257.857,8479.235,4257.092,8479.235Z"
      fill="#fff"
    />
    <path
      id="Path_142"
      data-name="Path 142"
      d="M4182.883,8430.793a19.538,19.538,0,0,1-4.948-.683c-5.245-1.375-8.8-5.436-9.75-11.14-.991-5.949,1.21-12.028,5.355-14.784a14.862,14.862,0,0,1,11.347-2.154,15.4,15.4,0,0,1,9.719,6.367c3.563,5.515,1.346,12.313,1.169,12.832C4195.494,8422.118,4192.492,8430.793,4182.883,8430.793Zm-1.178-25.906a11.394,11.394,0,0,0-6.4,1.952c-3.084,2.05-4.367,5.952-3.587,10.628.736,4.412,3.438,7.539,7.415,8.581,4.661,1.222,8.41.492,11.14-2.169a8.385,8.385,0,0,0,2.46-3.589l.027-.085c.02-.057,2.466-6.221-.3-10.51-1.554-2.405-5.025-3.879-8.216-4.544A12.424,12.424,0,0,0,4181.705,8404.887Z"
      fill="#fff"
    />
    <path
      id="Path_143"
      data-name="Path 143"
      d="M4161.146,8478.59h-.022c-.88-.012-1.853-.187-1.841-1.066.041-2.972.414-29.126,1.012-32.514l.064-.377c.628-3.7,1.933-11.4,18.672-11.606q2.114-.026,4-.061c13.244-.219,19.289-.318,22.017,7.236a47.142,47.142,0,0,1,2.266,16.057,1.593,1.593,0,1,1-3.182-.15c0-.08.345-8.108-2.08-14.824-1.878-5.2-6.126-3.973-19.613-3.748-1.261.02-2.6.042-4.011.06-14.081.174-15.013,5.67-15.569,8.954l-.069.4c-.467,2.65-.209,22.616-.318,30.619C4162.456,8478.439,4162.015,8478.59,4161.146,8478.59Z"
      fill="#fff"
    />
    <path
      id="Path_144"
      data-name="Path 144"
      d="M4171.414,8477.686a1.653,1.653,0,0,1-.315-.03c-.862-.173-1.488-.745-1.315-1.608.222-1.313-.256-13.021-.819-23.364a1.593,1.593,0,0,1,3.181-.174c.281,5.159,1.165,22.147.762,24.163C4172.756,8477.429,4172.157,8477.686,4171.414,8477.686Z"
      fill="#fff"
    />
    <path
      id="Path_145"
      data-name="Path 145"
      d="M4193.857,8477.031c-.765,0-1.069-.026-1.2-.806-.408-2.443-.062-22.911-.021-25.237a1.593,1.593,0,1,1,3.185.055c-.152,8.673-.292,23.038-.022,24.66.145.867-.812,1.162-1.679,1.307A1.624,1.624,0,0,1,4193.857,8477.031Z"
      fill="#fff"
    />
  </g>
</svg>;
