import { styled } from '@mui/material';
import { Box, Theme } from '@mui/system';

export const HeroBox = styled(Box)(({ theme }: { theme: Theme }) => ({
  background: theme.palette.primary.main,
  [theme.breakpoints.up('md')]: {
    width: '556px',
    borderRadius: '41px 41px 41px 0',
    padding: '35px 45px'
  }
}));
