import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { InformationSectionLink } from '../information-section-link';
import { LLBox } from '../primitives/ll-box';

export interface JobCardProps {
  jobTitle: string;
  department: string;
  location: string;
  href: string;
}

export const JobCard = (props: JobCardProps) => {
  const { jobTitle, department, location, href } = props;
  let link = `/positions/${href}`
  return (
    <LLBox border="1px solid #d5dae9" padding="35px">
      <Box>
        <Typography variant="h6">{jobTitle}</Typography>
        <Box my="15px">
          <Typography>{`Department: ${department}`}</Typography>
          <Typography>{`Location: ${location}`}</Typography>
        </Box>
        <InformationSectionLink href={link} label="View details" />
      </Box>
    </LLBox>
  );
};
